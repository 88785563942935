<template>
  <!-- Card -->
  <AppCard :size="'1/2xl'">
    <div>
      <!-- Headline -->
      <h2 class="additional-documents-title">{{ pageContent.headline }}</h2>

      <!-- Sub-headline -->
      <h4 class="type-caption text-gray pt-4">
        {{ pageContent.subHeadline }}
        <!-- display bolder text -->
        <span v-if="pageContent.highlightedSubHeadline" class="font-semibold text-main-black">
          {{ pageContent.highlightedSubHeadline }}
        </span>
      </h4>

      <!-- List of requirements -->
      <div
        v-if="isPageContentOther"
        class="type-caption text-gray list-disc pt-3 sm:pt-5 mb-2 sm:mb-10"
      >
        {{ otherDocumentRequestText }}
      </div>
      <ul v-else class="type-caption text-gray list-disc pl-6 pt-3 sm:pt-5 mb-2 sm:mb-10">
        <li v-for="({ text, highlightedText }, index) in pageContent.informationList" :key="index">
          {{ text }}
          <!-- display bolder text -->
          <span v-if="highlightedText" class="font-semibold text-main-black">
            {{ highlightedText }}
          </span>
        </li>
      </ul>
    </div>

    <!-- Upload file -->
    <XeFileUpload
      :key="pageContent.headline"
      class="py-4 mb-4"
      :display-options="showOptions"
      :is-download-enabled="isFileDataExist"
      :data="stateFile"
      :accept="acceptedFileTypes"
      :max-size="maxFileSize"
      :clear="clearUpload"
      :is-mobile-view="mq.current === 'xs'"
      theme="modern"
      :loading="isLoading"
      @uploaded="setFileUploaded"
      @cleared="uploadAgain"
    />

    <!-- Footer -->
    <AppModalFooter v-if="isFileUploaded && !isLoading" class="uploaded-file-button">
      <AppButton class="uploaded-file-button-secondary" theme="secondary" @click="uploadAgain">
        Upload file again
      </AppButton>
      <AppButton type="submit" class="uploaded-file-button-submit" @click="saveFile">
        Save & Continue
      </AppButton>
    </AppModalFooter>
  </AppCard>
</template>

<script>
import { computed, ref, reactive, watch } from '@vue/composition-api'
import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'

import { useMediaQuery } from '@en-ui/composables/useMediaQuery'

import AppCard from '@/components/AppCard/AppCard'
import AppButton from '@/components/AppButton/AppButton'
import AppModalFooter from '@/components/AppModalFooter/AppModalFooter'
import XeFileUpload from '@/components/XeFileUpload/XeFileUpload'
import { useDashboardStore } from '@/stores/dashboard'
import { useAnalyticsStore } from '@/stores/analytics'

const JSZip = require('jszip')

export default {
  components: {
    AppCard,
    AppButton,
    XeFileUpload,
    AppModalFooter,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const dashboardStore = useDashboardStore()
    const mq = reactive(useMediaQuery())
    const analyticsStore = useAnalyticsStore()

    // Default values
    const pageName = ref('additionalDocuments')
    const stepperItems = computed(() => dashboardStore.getStepperItems(pageName.value))
    const uploadedFilesSteps = computed(() => {
      const storedDocuments = dashboardStore.uploadedFile
      const fileDocuments = Object.values(storedDocuments)
      const steps = reactive({})
      fileDocuments.forEach((item, i) => (steps[Object.keys(storedDocuments)[i]] = item.name || ''))
      return steps
    })
    const hasItemsFilled = computed(() =>
      Object.values(uploadedFilesSteps.value).every((x) => x.toString() !== '')
    )
    const contentOptions = computed(() => {
      const { content } = dashboardStore.getStpPageById(pageName.value)
      return content.itemsContent
    })

    // Create steppers items
    const additionalDocumentItems = computed(() => {
      const documents = dashboardStore.documentaryEvidenceStatus
      return documents.map(({ id, name }) => ({ id, name }))
    })
    // Set items to display on stepper component
    if (!stepperItems.value) {
      // Set active item if list is empty
      additionalDocumentItems.value[0]['activeItem'] = true
      dashboardStore.setStepperItems({
        id: pageName.value,
        items: additionalDocumentItems.value,
      })
    }
    const activeItem = computed(() => stepperItems.value.find((item) => item.activeItem))

    // Get page content
    const pageContent = computed(() => {
      const itemsContent = contentOptions.value
      return itemsContent.find((item) => item.id === activeItem.value.id) || []
    })
    const otherDocumentRequestText = computed(() => dashboardStore.getOtherDocumentRequestText)
    const otherDocumentDisplayableIds = computed(
      () => dashboardStore.getOtherDocumentDisplayableIds
    )
    const isPageContentOther = computed(() =>
      otherDocumentDisplayableIds.value?.includes(pageContent.value?.id)
    )

    // Upload file logic
    const maxFileSize = ref(20)
    const clearUpload = ref(false)
    const fileUploadedUrl = ref('')
    const fileUploadedPdf = ref('')
    const openConfirm = ref(false)
    const acceptedFileTypes = ['application/pdf', 'image/jpg', 'image/png', 'image/jpeg']
    const getUploadedFile = computed(() => dashboardStore.uploadedFile[activeItem.value.id])
    const stateFile = ref(getUploadedFile.value)
    const isFileDataExist = ref(false)

    if (Object.values(uploadedFilesSteps.value).filter(Boolean).length === 0) {
      analyticsStore.track({
        event: 'Pending Action Started',
        traits: {
          ActionName: 'Additional Documents',
        },
      })
    }

    // check if data is arrayBuffer with byteLength
    if (stateFile?.value?.data) {
      if (stateFile?.value?.data?.byteLength) {
        isFileDataExist.value = true
      }
    }

    const showOptions = ref(stateFile && stateFile !== undefined)
    const isFileUploaded = ref(Object.keys(stateFile.value).length > 0)
    const isLoading = ref(false)
    const corpProfile = dashboardStore.corpProfile

    // Looking for files
    watch(getUploadedFile, (data) => {
      isFileUploaded.value = Object.keys(data).length > 0
      stateFile.value = data
    })

    watch(stateFile, (data) => {
      isFileUploaded.value = Object.keys(data).length > 0
      isFileDataExist.value = data?.data?.byteLength ? true : false
    })

    const setFileUploaded = (uploadedFile) => {
      if (!uploadedFile) {
        fileUploadedUrl.value = ''
        fileUploadedPdf.value = ''
        return
      }

      if (uploadedFile) {
        const { data, name } = uploadedFile
        let file = uploadedFile

        const zip = new JSZip()
        zip.file(`${name}`, data)
        zip.generateAsync({ type: 'arraybuffer' }).then((content) => {
          file.data = content
          stateFile.value = file
        })
      }

      if (
        uploadedFile.type === 'image/png' ||
        uploadedFile.type === 'image/jpg' ||
        uploadedFile.type === 'image/jpeg'
      ) {
        fileUploadedUrl.value = uploadedFile.data
      } else if (uploadedFile.type === 'application/pdf') {
        fileUploadedPdf.value = uploadedFile.data
      }
      openConfirm.value = true
      clearUpload.value = false
      showOptions.value = true
    }

    const goToPendingActions = () => {
      router.push({ name: 'PendingActions' })
    }

    const uploadAgain = () => {
      clearUpload.value = true
      fileUploadedUrl.value = ''
      fileUploadedPdf.value = ''
      dashboardStore.setClearFile(activeItem.value.id)
      openConfirm.value = false
    }

    const saveStepperItem = () => {
      if (!!activeItem.value.id && !hasItemsFilled.value) {
        dashboardStore.setStepperValue({
          itemId: activeItem.value.id,
          targetKey: pageName.value,
          value: '',
          setChecked: true
        })
      } else {
        goToPendingActions()
      }
    }

    const alreadyUploadedFiles = computed(() => dashboardStore.uploadedFile)

    const saveFile = async () => {
      isLoading.value = true
      const formData = new FormData()

      const downloadFile = (file, sendFile) => {
        if (
          !Object.values(alreadyUploadedFiles.value).find(
            (doc) => !!doc.name && doc.name === file.name && doc.size === file.size
          )
        ) {
          JSZip.loadAsync(file.data).then((zip) => {
            zip.files[file.name].async('blob').then((fileData) => {
              sendFile(fileData)
            })
          })
        } else {
          // if last item, go pending action
          if (
            stepperItems.value.indexOf(stepperItems.value.find((i) => i.activeItem === true)) ===
            stepperItems.value.length - 1
          ) {
            isLoading.value = false
            goToPendingActions()
          } else {
            isLoading.value = false
            saveStepperItem()
          }
        }
      }

      const sendFile = async (file) => {
        const fileSize = stateFile.value.size
        const fileType = stateFile.value.type

        const regeneratedFile = new File([file], stateFile.value.name, {
          size: fileSize,
          type: fileType,
        })

        formData.append('file', regeneratedFile)

        await dashboardStore.saveFile({
          docType: activeItem.value.id,
          country: corpProfile.registrationAddress.country,
          companyType: corpProfile.companyDetails.companyTypeAcronym,
          formData,
          file: stateFile.value,
          goToPendingActions: false,
        })

        // Save event
        const { id } = pageContent.value
        await analyticsStore.track({
          event: 'Document Upload Saved',
          traits: {
            documentName: additionalDocumentItems.value.find((i) => i.id === id).name,
            documentFileType: stateFile.value.type.split('/')[1],
          },
        })
        saveStepperItem()
        isLoading.value = false
      }

      downloadFile(stateFile.value, sendFile)
    }

    return {
      hasItemsFilled,
      uploadedFilesSteps,
      pageContent,
      additionalDocumentItems,
      acceptedFileTypes,
      goToPendingActions,
      fileUploadedUrl,
      fileUploadedPdf,
      openConfirm,
      uploadAgain,
      saveFile,
      setFileUploaded,
      isLoading,
      isFileUploaded,
      showOptions,
      clearUpload,
      getUploadedFile,
      maxFileSize,
      stateFile,
      isFileDataExist,
      mq,
      isPageContentOther,
      otherDocumentRequestText,
    }
  },
}
</script>

<style lang="postcss" scoped>
.additional-documents {
  &-title {
    @apply type-h2;

    @screen sm {
      @apply type-h1;
    }

    @screen md {
      @apply type-stp-title;
    }
  }
}

.card-content {
  @apply px-4 pb-12 !important;

  @screen sm {
    @apply px-12 !important;
  }

  .card-content-block {
    @apply mb-0 !important;
  }
}

.uploaded-file-button {
  flex-direction: column;
  @apply px-4 pb-4;

  @screen sm {
    @apply flex-row justify-end;
  }

  >>> .button-inner {
    @apply py-3 px-6 !important;
  }

  &-secondary {
    @apply order-2 bg-tertiary-blue text-main-cyan;

    @screen sm {
      @apply order-1 mr-3;
    }
  }

  &-submit {
    @apply mb-3;

    @screen sm {
      @apply order-2 mb-0;
    }
  }

  .button {
    @apply rounded-xl !important;
  }
}
</style>
